import * as React from "react";

const Contact = () => {
    return(
        <>
        <section className="section-wrapper contact-section">
            <h1>Want to contact me?</h1>
            <h3>Drop me an email</h3>
            <h2>shane@ducksbury.net</h2>
        </section>
        </>
    )
}

export default Contact;